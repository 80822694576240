<template>
  <div id="dashboard-analytics">
    <global-events :user="this.userInfo.user"/>
    <div class="vx-row w-full lg:w-1/1 mb-base">

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <div class="create-project-container">
            <h2 class="card-title">Crear proceso</h2>
            <p class="card-text">Te permitirá eligir los servicios que requieres para tomar las mejores decisiones al momento de gestionar el talento humano de tu organización.</p>
            <vs-button id="btnCreateProject" color="primary" @click="showForm()">Crear proceso</vs-button>
          </div>
        </vs-col>

        <vs-col vs-type="flex banner" vs-justify="center" vs-align="center" vs-w="6" >
            <carousel class="w-full" :per-page="1" :mouse-drag="false" :autoplayTimeout=8000 :scrollPerPage="true" paginationActiveColor='#FFC057' :paginationPadding=10 :paginationSize=7 paginationPosition="bottom-overlay" :navigationClickTargetSize=4 autoplay loop>
              <slide v-for="banner in bannerInfo.data" :key="banner.id" class="carousel-container">
                <img v-if="banner.redirection_url" @click="navigateTo(banner.redirection_url, userInfo.user.client_id, userInfo.user.id, banner.id)" :src="banner.url" class="slide-carousel"/>
                <img v-else style="cursor: default;" :src="banner.url" class="slide-carousel"/>
              </slide>
            </carousel>
        </vs-col>
    </div>

    <div class="vx-row w-full lg:w-1/1 mb-base">
      <vs-col vs-type="flex" vs-w="12">
        <div class="fast-follow-container w-full">
            <vs-button class="btn-secondary" :class="isActive ? 'btn-shadow' : ''" color="#fff" @click="toggleShadow('fastFollowing')">Seguimiento rápido</vs-button>
            <vs-button v-if="userInfo.user.express_search_enabled" class="btn-secondary" :class="isActive ? '' : 'btn-shadow'" color="#fff" @click="toggleShadow('stateFollowing')">Consulta exprés <span class="beta">Beta</span></vs-button>
            <vs-button v-else class="btn-secondary" :class="isActive ? '' : 'btn-shadow'" color="#fff" @click="toggleShadow('stateFollowing')">Seguimiento por estados</vs-button>
        </div>
      </vs-col>
    </div>

      <!-- pipeline or trello -->

      <div class="vx-col w-full lg:w-1/1 mb-base cont-traceability" v-if="switchTablePipeline">
        <!-- <Events v-if="classification != null"/> -->
        <!-- <Loader :show="showLoader" v-else/> -->
        <!-- <search-bar/> -->
        <process-detail/>
      </div>

      <div class="vx-col w-full lg:w-1/1 mb-base" v-else>
        <div v-if="userInfo.user.express_search_enabled">
          <express-search-layout/>
        </div>
        <div v-else>
          <project-status/>
        </div>
      </div>

      <!-- end pipeline or trello -->

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import LineAreaChart from '../components-globalwork/LineAreaChart.vue'
import Notifications from '../components-globalwork/Notifications.vue'
import GlobalEvents from '../components-globalwork/global-events.vue'
import Certificate from '../components-globalwork/Certificate.vue'
import ProjectsStatus from '../components-globalwork/ProjectsStatus.vue'
import Traceability from './../components-globalwork/traceability/Traceability.vue'
import Events from './../components-globalwork/traceability/Events.vue'
import ProjectsOverallFinal from '../components-globalwork/ProjectsOverallFinal.vue'
import Loader from './Loader.vue'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import themeConfig from '@/../themeConfig.js';
const VxTour = () => import('@/components/VxTour.vue')
import api from '../mixins/api.js'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import { mapGetters, mapMutations } from 'vuex'
import GoToPoll from './../components-globalwork/GoToPoll.vue'
import Search from '../components-globalwork/fast-tracking/Search.vue'
import ProcessDetail from '../components-globalwork/fast-tracking/ProcessDetail.vue'
import Layout from '../components-globalwork/express-search/Layout.vue'

export default {
  mixins: [api],
  computed: {
    ...mapGetters(['userInfo', 'eventTypes'])
  },
  data() {
    return {
      bannerInfo: [],
      showLoader: true,
      switchTablePipeline: true,
      classification: null,
      clientId: null,
      authorizeCreatingContract: false,
      messageAuthorizaCreatingProject: 'Verificando contratos activos ...',
      project_create: false,
      popupActive: false,
      perPage: 10,
      page: 1,
      isActive: true,
      swiperOption: {
        pagination: {
            el: '.swiper-pagination',
            // type: 'progressbar'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
      },
      data: [
        '<div class="example-slide">Slide 1</div>',
        '<div class="example-slide">Slide 2</div>',
        '<div class="example-slide">Slide 3</div>',
      ],
      user: {},
      project: {},
      users: [
        {
          id: 0,
          first_name: "David Mauricio",
          last_name: "Gómez Zapata",
          gender: 1,
          email: "damagoza1kkk@gmail.com",
          telephone: "3175489822"
        },
        {
          id: 0,
          first_name: "David Mauricio",
          last_name: "Gómez Zapata",
          gender: 1,
          email: "damagoza2@gmail.com",
          telephone: "3175489822"
        },
        {
          id: 0,
          first_name: "David Mauricio",
          last_name: "Gómez Zapata",
          gender: 1,
          email: "damagoza3@gmail.com",
          telephone: "3175489822"
        },
        {
          id: 0,
          first_name: "David Mauricio",
          last_name: "Gómez Zapata",
          gender: 1,
          email: "damagoza4@gmail.com",
          telephone: "3175489822"
        },
        {
          id: 0,
          first_name: "David Mauricio",
          last_name: "Gómez Zapata",
          gender: 1,
          email: "damagoza5@gmail.com",
          telephone: "3175489822"
        }
      ],
      disableThemeTour: themeConfig.disableThemeTour,
      steps: [
          {
              target: '#content-area > div.content-wrapper > div.relative > div > header > div.vs-con-items > ul > div > li:nth-child(1)',
              content: 'Regresa a la página principal'
          },
          {
              target: '#content-area > div.content-wrapper > div.relative > div > header > div.vs-con-items > ul > div > li:nth-child(2)',
              content: 'Administra los usuarios de tu empresa que tienen acceso a la plataforma'
          },
          {
              target: '#content-area > div.content-wrapper > div.relative > div > header > div.vs-con-items > ul > div > li:nth-child(3)',
              content: 'Conoce el estado y los avances de cada proceso'
          },
          {
              target: '#content-area > div.content-wrapper > div.relative > div > header > div.vs-con-items > span.cursor-pointer.navbar-fuzzy-search.ml-4.mr-6.mt-1.feather-icon.select-none.relative',
              content: 'Encuentra el aspirante que necesitas con su nombre o cédula'
          },
          {
              target: '#content-area > div.content-wrapper > div.relative > div > header > div.vs-con-items > button',
              content: 'Conoce las actualizaciones más importantes de los procesos'
          },
          {
              target: '#dashboard-analytics > div:nth-child(2) > div > div > div > div:nth-child(2) > div.point-notifications',
              content: 'Infórmate de los avances de cada validación. Al pasar el mouse sobre la barra, verás el porcentaje de progreso',
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(1) > div > div',
              content: 'Obtén estadísticas en tiempo real, filtrando por fechas y ciudades'
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(1) > div > div > div.vx-card__collapsible-content.vs-con-loading__container > div > form > div > div:nth-child(1) > div > div:nth-child(1) > input[type=text]',
              content: 'Selecciona fecha de inicio'
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(1) > div > div > div.vx-card__collapsible-content.vs-con-loading__container > div > form > div > div:nth-child(2) > div > div:nth-child(1) > input[type=text]',
              content: 'Selecciona fecha de finalización'
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(1) > div > div > div.vx-card__collapsible-content.vs-con-loading__container > div > form > div > div:nth-child(3) > button',
              content: 'Consultar'
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(2) > div > div > div.vx-card__header',
              content: 'Número de solictudes por mes'
          },
          {
              target: '#dashboard-analytics > div:nth-child(3) > div:nth-child(2) > div > div > div.vx-card__collapsible-content.vs-con-loading__container > div > form > div > div:nth-child(3) > div > div > div.vs__selected-options > input',
              content: 'Selecciona ciudad'
          },
      ],
      project_id_create:0
    }
  },
  methods:{
    ...mapMutations(['changeColumnEvent']),
    numberProjectsDisplayed () {
      const classification = this.classification
      const typeEvents = ['request_process', 'information_completed','request_validations','verifications_response','project_finished']
      let number = 0
      typeEvents.forEach((value) => { number += classification[value].length })
      return number
    },
    navigateTo(url, client_id, user_id, banner_id){
      window.open(url)
      this.requestPutWithoutContent(`${this.url()}api/v1/banner_clicks`, {"banner_picture":{"client_id": client_id, "user_id": user_id, "banner_id": banner_id} })
    },
    showForm(){
      this.$router.push('/project_new')
    },
    closePopups(popupShow){
       if ('popupActive' != popupShow){
         this.popupActive = false
       }
       if ('popupShowActive' != popupShow){
         this.popupShowActive = false
       }
       if ('popupUploadActive' != popupShow){
         this.popupUploadActive = false
       }
       if ('popupSearch' != popupShow){
         this.popupSearch = false
       }
       if ('popupUploadMasiveActive' != popupShow){
         this.popupUploadMasiveActive = false
       }
    },
    resetFormProject(){
      this.project = {}
      this.create = true;
    },
    async getContractsActive(){
      var path = "api/v1/clients/"+this.clientId+"/contracts_active"
      var url = this.url()+path

      this.requestGetWithoutContent(url, {}).then(response => {
        if (response.status == 200){
          let contracts = response.data
          if (contracts.length > 0){
            this.authorizeCreatingContract = true
          }else{
            this.messageAuthorizaCreatingProject = 'Actualmente no posees contratos activos'
          }
        }else{
          this.messageAuthorizaCreatingProject = 'No se pudo obtener los contratos '
        }
      }, (err) => {
        this.messageAuthorizaCreatingProject = err.response.data.error
      })
    },
    async getProjectsClassification() {
      var user_id = this.userInfo.user.id
      const classification = await this.requestGetWithoutContent(`${this.url()}api/v1/projects/projects_traceability?user_id=${user_id}&per_page=${this.perPage}&page=${this.page}`, {})
      this.classification = classification.data
      this.showLoader = false
    },
    async getBannerInformation() {
      const bannerInfo = await this.requestGetWithoutContent(`${this.url()}api/v1/banner_pictures`, {})
      this.bannerInfo = bannerInfo.data
    },
    getProjectsByTypeEvent() {
      this.eventTypes.forEach(eventType => {
        this.requestGetWithoutContent(`${this.url()}api/v1/projects/projects_by_event_type?user_id=${this.userInfo.user.id}&event_type=${eventType.id}&per_page=${this.perPage}&page=${this.page}`, {}).then((response) => {
          this.changeColumnEvent({ event_type: eventType.name, data: response.data})
        })
      });
    },
    toggleShadow(value){
      if(value === 'fastFollowing'){
        this.isActive = true
        this.switchTablePipeline = true
      } else if (value === 'stateFollowing'){
        this.isActive = false
        this.switchTablePipeline = false
      }
    }

  },
  components: {
    'line-area-chart': LineAreaChart,
    'notifications': Notifications,
    'certificate': Certificate,
    'project-status': ProjectsStatus,
    Traceability,
    Events,
    'project-overall-final': ProjectsOverallFinal,
    swiper,
    swiperSlide,
    VxTour,
    Datepicker,
    FormWizard,
    TabContent,
    vSelect,
    'global-events': GlobalEvents,
    Loader,
    GoToPoll,
    'search-bar': Search,
    'process-detail': ProcessDetail,
    'express-search-layout': Layout,
  },
  watch: {
    page() {
      this.getProjectsClassification()
    }
  },
  mounted(){
    this.getBannerInformation()
  },
  created () {
    if(this.userInfo == null ){
      window.localStorage.clear()
      this.$router.push('/pages/login')
    }else{
      this.clientId = this.userInfo.user.client_id
      this.getContractsActive()
      this.getProjectsClassification()
      this.getProjectsByTypeEvent()
    }
  },
}
// ;(function(d, w, c) {
//   w.ChatraID = 'FcAMLEj7MZgafY8hi';
//   var s = d.createElement('script');
//   w[c] = w[c] || function() {
//       (w[c].q = w[c].q || []).push(arguments);
//   };
//   s.async = true;
//   s.src = 'https://call.chatra.io/chatra.js';
//   if (d.head) d.head.appendChild(s);
// })(document, window, 'Chatra');
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user{
    position: relative;
    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
.cont-traceability {
  margin-bottom: 1.5rem !important;
}
.container-switch-pipeline-table {
  display: flex;
  flex-direction: row;
  margin-left: 1.3rem;
  margin-bottom: 1rem
}
.label-switch-pipeline-table {
  margin-right: 0.5rem;
}

.card-title{
  /* Título H3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  // line-height: 35px;
  /* identical to box height */
  /* Morado Globalwork */
  color: #5F008F;
}

.card-text{
  font-family: 'Montserrat';
  // font-style: normal;
  font-size: 1.1rem;
  font-weight: 400;
  color: #3C1A4E;
  padding: 16px 0px;
}

#btnCreateProject {
  // font-family: 'Montserrat';
  // padding: 7px 12px;
  // font-size: 0.9rem;
  font-weight: bold;
}

.fast-follow-container{
  padding: 0px 16px;
  gap: 24px;
  background: #FCFBFE;
  border-radius: 8px;
}

.btn-secondary{
  color: #5F008F;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  text-align: left;
  line-height: 16px;
  margin-right: 16px;
  margin-bottom: 2px;
  width: 20rem;
  height: 2.6rem;
  background: #FCFBFE;
  border-radius: 8px;
}

.btn-shadow{
  box-shadow: 0px 0px 6px rgba(95, 0, 143, 0.25);

  &:hover{
    box-shadow: 0px 0px 6px rgba(95, 0, 143, 0.25) !important;
  }
}

.create-project-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 16px;
  height: 100%;

  /* Degrade Claro */

  background: linear-gradient(127.89deg, #F7F5FA -1.68%, #ECC9FF 105.75%);
  border-radius: 16px;
}

.carousel-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  max-height: 200px;
}

.slide-carousel {
  height: 100%;
  width: 100%;
}

.banner {
  cursor: pointer;
}

.VueCarousel-dot:focus {
  outline:0 !important;
}

.VueCarousel-pagination {
  pointer-events: none;
}

.VueCarousel-dot-container {
  pointer-events: auto;
}

.beta {
  background-color: #ECC9FF;
  font-size: 0.8rem;
  padding: 2px 10px;
  border-radius: 5px;
}
</style>
