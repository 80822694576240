var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-row w-full" }, [
    _c("div", { staticClass: "vx-col w-full" }, [
      _c(
        "div",
        { staticClass: "fatal-error-container" },
        [
          _c("p", { staticClass: "fatal-error-title" }, [
            _vm._v("¡Ups! Parece que hay un problema con su conexión"),
          ]),
          _c("img", {
            attrs: {
              src: require("../assets/images/errors/fatal-error-1.png"),
            },
          }),
          _c("p", { staticClass: "fatal-error-message" }, [
            _vm._v(
              "Estamos experimentando intermitencias en la obtención de la información con la consulta exprés Intenta de nuevo o vuelve más tarde."
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "fatal-error-btn-error",
              on: {
                click: function ($event) {
                  return _vm.refresh()
                },
              },
            },
            [_vm._v("Realizar Consulta de nuevo")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }