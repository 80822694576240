<template>
  <div>
      <div class="vx-row steps-page" style="justify-content: start;">
        <div class="vx-col" style="width: 100%; display: flex; align-items: center;">
          <span class="center-all">
            <transition name="fade" :duration="{ enter: 500, leave: 500 }">
                <img v-if="this.chekc_one" src="./../../assets/images/icons/green-check.png" style="width:16px;"/>
            </transition>
            <span :class="{ 'current-step' : step1 === true }">&nbsp;1. Habeas Data&nbsp;</span> |&nbsp;
          </span>

          <span class="center-all">
            <transition name="fade" :duration="{ enter: 500, leave: 500 }">
                <img v-if="this.chekc_two" src="./../../assets/images/icons/green-check.png" style="width:16px;"/>
            </transition>
            <span :class="{ 'current-step' : step2 === true }">&nbsp;2. Documento&nbsp;</span> |&nbsp;
          </span>

          <span class="center-all">
            <transition name="fade" :duration="{ enter: 500, leave: 500 }">
                <img v-if="this.chekc_three" src="./../../assets/images/icons/green-check.png" style="width:16px;"/>
            </transition>
            <span :class="{ 'current-step' : step3 === true }">&nbsp;3. Nivel académico</span>
          </span>

        </div>
      </div>

      <transition name="slide-fade" :duration="{ enter: 500, leave: 500 }">
        <div v-if="step1">
          <div class="vx-row step-row">

            <div class="vx-col step-container">
              <div class="vx-row" style="justify-content: space-between;">
                <div class="vx-col">
                  <h3 class="step-title">1. Habeas Data</h3>
                </div>
                <div class="vx-col" style="width:30%;">
                  <button class="info-btn" @click="displayInfoModal()">
                    ¿Cómo subir un archivo válido?
                  </button>
                  <vs-popup   title="" :active.sync="popupActive">
                    <info-modal/>
                  </vs-popup>

                </div>
              </div>

              <div v-if="this.serverResponse">

                <div v-if="this.errors_habeas.length > 0" class="errors-list">

                  <div class="vx-row">
                    <div class="vx-col">
                      <img src="./../../assets/images/icons/wrong-circle.png" style="width:16px;"/>
                    </div>
                    <div class="vx-col">
                      <span class="error-message"><strong>Verificación no exitosa del documento.</strong> El documento no cuenta con:</span>
                      <ul v-for="(error, index) in errors_habeas" :key="error">
                        <li class="error-message">{{index + 1}}. {{ error }}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="vx-row" style="justify-content: center; padding: 1% 0%;">
                    <div class="vx-col" style="width:50%;">
                      <vs-button class="reload-btn" @click="reset_step1()">
                        Subir Habeas Data de nuevo
                      </vs-button>
                    </div>
                  </div>

                </div>

                <div v-else>
                  <div v-if="this.all_info_extracted">
                    <div class="checked-habeas">
                      <img src="./../../assets/images/icons/green-check.png" style="width:16px;"/>
                      <span class="habeas-succeed-text"> Documento comprobado</span>
                    </div>
                    <div class="checked-habeas">
                      <div class="spinner-backgound"><img src="./../../assets/images/icons/pdf-icon.png" style="width:16px;"/></div>
                      <span class="habeas-file-name"> {{ this.habeas_file.name }}</span>
                    </div>
                  </div>
                </div>

              </div>

              <div v-else>

                <div v-if="processing_habeas">
                  <div class="vx-row center-all">
                    <div class="vx-col" style="width:100%; padding-top: 2.5%;">
                        <div class="load-habeas">
                          <div class="spinner-backgound"><span id="loading"></span></div>
                          <span class="habeas-loading-text">Confirmando Habeas Data...</span>
                        </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <p class="step-body">
                    Adjunta el Habeas Data de la persona que quieres validar. Usa la plantilla de Globalwork en formato .PDF para validar el documento.
                  </p>
                  <div class="load-input" @click="uploadFile" style="display: flex; align-items: center;">
                    <img src="./../../assets/images/icons/clip.png" style="width:16px;"/>&nbsp;
                    <span>
                      Arrastra y suelta el Habeas Data del candidato aquí o haz clic para buscar en tu ordenador.
                    </span>
                  </div>
                    <transition name="slide-fade">
                      <p v-if="this.timeout_error" class="timeout-error"><strong>Algo salió mal. Por favor carga el archivo nuevamente.</strong></p>
                    </transition>
                </div>
              </div>

            </div>

            <div class="vx-col center-all" style="flex-grow: 1; width:40%;">
              <img src="./../../assets/images/express-search/banner-step1.png" class="banners"/>
            </div>

          </div>
        </div>
      </transition>

      <transition name="slide-fade" :duration="{ enter: 500, leave: 0 }">
        <div v-if="step2">
          <div class="vx-row step-row">

            <div class="vx-col step-container">
              <h3 class="step-title">2. Documento</h3>
              <div v-if="!wrong_data">
                <p class="step-body">
                  El Habeas Data Corresponde a la persona
                </p>
                <div class="vx-row" style="justify-content:space-between;">
                  <div class="vx-col" style="width:50%;">
                    <div class="person_name_box">
                      <div class="vx-row" style="justify-content: space-between;">
                        <div class="vx-col">
                          <div class="person_name">
                            <!-- <p><strong>John Doe</strong></p>
                            <p>CC 1212112111</p> -->
                            <p><strong>{{ this.extracted_data.name }}</strong></p>
                            <p>CC {{ this.extracted_data.id }}</p>
                          </div>
                        </div>
                        <div class="vx-col center-all">
                          <img src="./../../assets/images/icons/person-icon.png" style="width: 24px;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col" style="width:50%;">
                    <div class="cofirmation_box" >
                      <div class="vx-row" >
                        <div class="vx-col" style="width:60%;">
                          <p class="person_name">¿Es la persona que quieres validar?</p>
                        </div>
                        <div class="vx-col center-all" style="width:20%;">
                          <div class="confirmation-icon-backgorund center-all" @click="updateStep('step3')"><img src="./../../assets/images/icons/green-check-outline.png" style="width:18px;"/></div>
                        </div>
                        <div class="vx-col center-all" style="width:20%;">
                          <div class="confirmation-icon-backgorund center-all" @click="wrongData()"><img src="./../../assets/images/icons/wrong-circle.png" style="width:18px;"/></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div v-else >
                <div class="wrong-person">
                  <img src="./../../assets/images/icons/wrong-circle.png" style="width:16px;"/>&nbsp;
                  <span>Has seleccionado que la persona a la que quieres validar no es la correcta.</span>
                </div>
                <div class="vx-row">
                  <div class="vx-col center-all" style="width: 100%;">
                    <vs-button class="reload-btn" @click="reset_step1()" style="width: 50%;">
                      Volver a subir habeas data de nuevo
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-col " style="width:40%;">
              <div class="vx-row center-all" style="width: 100%;">
                <div class="vx-col" style="width: 80%; min-height: 26px;">
                  <transition name="slide-fade">
                    <div v-if="this.chekc_two" class="right-person" >¡Muy bien, vamos por el último paso!</div>
                  </transition>
                </div>
              </div>

              <div class="vx-row center-all" style="width: 100%; max-height:180px;">
                <div class="vx-col border-card" style="width: 80%;">
                  <div class="vx-row" style="width: 100%;">
                    <div class="vx-col" style="width: 40%; padding: 5%; %max-height:150px;">
                      <div class="border-card center-all" style="width: 100%; padding: 5%; max-height:120px;">
                        <img src="./../../assets/images/express-search/person.png" style="width: 100px; height:100px;"/>
                      </div>
                    </div>
                    <div class="vx-col" style="width: 40%; padding-top: 5%; max-height:150px;">
                      <p class="border-card" style="width: 40%; height: 13%; margin: 10% 0;"></p>
                      <p class="border-card" style="width: 80%; height: 13%; margin: 10% 0;"></p>
                      <p class="border-card" style="width: 100%; height: 13%; margin: 10% 0;"></p>
                    </div>
                    <div class="vx-col" style="width: 15%; padding-top: 5%; max-height:150px;">
                        <transition name="slide-fade">
                          <img v-if="this.chekc_two" src="./../../assets/images/icons/green-check.png" style="width:4.5rem;"/>
                        </transition>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </transition>

      <transition name="slide-fade" :duration="{ enter: 900, leave: 500 }">
        <div v-if="step3">
          <div class="vx-row step-row">
            <div class="vx-col step-container">
              <h3 class="step-title">3. Nivel Académico</h3>
              <p class="step-body">Selecciona uno para comenzar la búsqueda inmediatamente.</p>
              <div class="vx-row">
                <div class="vx-col" style="width:50%;">
                  <div class="cofirmation_box">
                    <div class="vx-row">
                      <div class="vx-col" style="width: 5%;">
                        <input type="radio" id="radio_basic" v-model="academic_level" name="academic_level" value="0" class="radio-academic-level">
                      </div>
                      <div class="vx-col" style="width: 80%;">
                        <span class="step3-body-card-title">Básico</span>
                        <p class="step3-body-card">Buscaremos en algunas instituciones técnicas información del candidato.</p>
                      </div>
                      <div class="vx-col" style="width: 5%;">
                        <img src="./../../assets/images/icons/profesional-icon.png" style="width: 20px;">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-col" style="width:50%;">
                  <div class="cofirmation_box">
                    <div class="vx-row">
                      <div class="vx-col" style="width: 5%;">
                        <input type="radio" id="radio_profesional" v-model="academic_level" name="academic_level" value="1" class="radio-academic-level">
                      </div>
                      <div class="vx-col" style="width: 80%;">
                        <span class="step3-body-card-title">Universitario</span>
                        <div v-if="!displayProfesionalLevels">
                          <p class="step3-body-card">Buscaremos en algunas instituciones de pregrado, posgrado y/o colegios profesionales.</p>
                        </div>
                        <div v-else>
                          <select class="select-levels" v-model="selected_profesional_level">
                            <option :value="null" disabled>Selecciona una profesión</option>
                            <option v-for="level in profesional_levels" :value="level.value" :key="level.id">
                            {{ level.value }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="vx-col" style="width: 5%;">
                        <img src="./../../assets/images/icons/profesional-icon.png" style="width: 20px;">
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col center-all" style="flex-grow: 1; width:40%;">
              <img src="./../../assets/images/express-search/banner-step3.png" class="banners"/>
            </div>
          </div>
        </div>
      </transition>

      <transition name="slide-fade" :duration="{ enter: 500, leave: 500 }">
        <div v-if="step4">
          <div class="vx-row step-row">
              <div class="vx-col step-container">
                <div class="vx-row" style="justify-content: space-between;">
                  <div class="vx-col">
                    <h3 class="step-title">Verificando datos</h3>
                      <div style="padding: 2% 0;">
                        <!-- <p class="step4-card-text"><strong>John Doe</strong></p>
                        <p class="step4-card-text">CC 123456789</p> -->
                        <p class="step4-card-text"><strong>{{ this.extracted_data.name }}</strong></p>
                        <p class="step4-card-text">CC {{ this.extracted_data.id }}</p>

                        <p class="step4-card-text" v-if="academic_level === '0'">Nivel Académico Básico</p>
                        <p class="step4-card-text" v-if="academic_level === '1'">Nivel Académico Profesional</p>
                      </div>
                  </div>
                  <div class="vx-col center-all" style="width:20%;">
                    <img src="./../../assets/images/icons/person-icon.png" style="width: 30px;">
                  </div>
                </div>
              </div>
              <div class="vx-col center-all" style="flex-grow: 1; width:40%;">
                <img src="./../../assets/images/express-search/banner-step4.png" class="banners"/>
              </div>
          </div>
        </div>
      </transition>

      <transition name="slide-fade" :duration="{ enter: 500, leave: 500 }">
          <div v-if="step4" class="vx-row">
            <div class="vx-col loader-bar">
              <span class="loader-bar-text"> <span>{{ progress }}%, </span> Cargando resultados, esto puede tardar unos segundos...</span>
              <div>
                  <vs-progress :percent="progress" :indeterminate="progress < 1" color="primary">primary</vs-progress>
              </div>
            </div>
          </div>
      </transition>

      <div>
        <div>
          <input id="habeasFile" type="file" ref="file" accept="image/jpeg,.pdf" style="display:none" @change="onFileChange">
        </div>
      </div>

      <div v-if="step4">
        <div>
          <general-section :name='extracted_data.name' :express_search_id="express_search_id" :identification_number='extracted_data.id' :academic_level='academic_level' :profesional_level="selected_profesional_level" @progress="setProgress" @reset="reload" />
          <!-- <general-section name='David Medina Arias' :express_search_id='34' identification_number='1128470652' academic_level='0' profesional_level="" @progress="setProgress" @reset="reload"/> -->
        </div>
      </div>

  </div>
</template>

<script>
  import api from '../../mixins/api.js'
  import FatalError from '../FatalError.vue'
  import InfoModal from './InfoModal.vue'
  import GeneralSection from './GeneralSection.vue'

  export default {
    mixins: [api],

    data(){
      return {
        serverError: false,
        serverResponse: false,
        timeout_error: false,
        step1: true,
        step2: false,
        step3: false,
        step4: false,
        progress: 0,
        chekc_one: false,
        chekc_two: false,
        chekc_three: false,
        popupActive: false,
        processing_habeas: false,
        habeas_file: null,
        all_info_extracted: false,
        extracted_data: null,
        express_search_id: null,
        errors_habeas: [],
        wrong_data: false,
        academic_level: null,
        displayProfesionalLevels: false,
        selected_profesional_level: null,
          profesional_levels: {
            0:{id: 0, value: 'Abogado',},
            1:{id: 1, value: 'Administrador de empresas',},
            2:{id: 2, value: 'Arquitecto',},
            3:{id: 3, value: 'Contador',},
            4:{id: 4, value: 'Economista',},
            5:{id: 5, value: 'Ingeniero',},
            6:{id: 6, value: 'Médico',},
            7:{id: 7, value: 'Químico',},
            8:{id: 8, value: 'Técnico electricista',},
            9:{id: 9, value: 'Trabajador social',},
            10:{id: 10, value: 'Zootecnista-Veterinario',},
          }
      }
    },

    components: {
      'fatal-error': FatalError,
      'info-modal': InfoModal,
      'general-section': GeneralSection
    },

    watch: {
      'this.$refs.file.files'(value) {
        if (value !== null){
          this.onFileChange()
        }
      },
      'academic_level'(value) {
        this.academic_selection(value)
      },
      'selected_profesional_level'(value){
        if (value !== null) {
          this.chekc_three = true
          setTimeout(()=>{
            this.updateStep('step4')
          }, 1000);
          this.createExpressSearch()
        }
      },
    },

    methods: {
      updateStep(value){
        if (value == 'step1') {
          setTimeout(()=>{
            this.step1 = true
          }, 500);
          this.step2 = false
          this.step3 = false
          this.step4 = false
        } else if(value == 'step2') {
          this.step1 = false
          setTimeout(()=>{
            this.step2 = true
          }, 500);
        } else if(value == 'step3') {
          this.chekc_two = true
          setTimeout(()=>{
            this.step2 = false
          }, 2000);
          this.createExpressSearch()
          setTimeout(()=>{
            this.step3 = true
          }, 2000);
        } else if(value == 'step4') {
          this.step3 = false
          setTimeout(()=>{
            this.step4 = true
          }, 500);
        }
      },

      uploadFile() {
        const fileElem = document.getElementById("habeasFile");
        fileElem.click()
      },

      onFileChange(){
        this.habeas_file = this.$refs.file.files[0];
        let check = this.checkFile(this.habeas_file)
        if (this.habeas_file !== null && check) {
          this.callOcr()
        }
      },

      checkFile(file){
        if (!['application/pdf', 'image/jpeg'].includes(file.type)) {
          this.errors_habeas.push('Formato de archivo no valido')
          return false
        }
        return true
      },

      reset_step1(){
        if (this.step2) {
          this.updateStep('step1')
          this.chekc_one = false
          this.chekc_two = false
          this.wrong_data = false
          this.serverResponse = false
        }
        this.habeas_file = null,
        this.all_info_extracted = false
        this.$refs.file.value = null
        this.uploadFile()
      },

      wrongData(){
        this.wrong_data = true
      },

      set_ocr_findings(data){
        this.errors_habeas = data.errors
        this.all_info_extracted = data.complete_info
        this.extracted_data = data.info
        if (this.all_info_extracted) {
          // move to step 2
          this.popupActive = false
          this.chekc_one = true
          setTimeout(()=>{
            this.updateStep('step2')
          }, 1000);
        }
      },

      academic_selection(level){
        if(level === '0'){
          this.chekc_three = true
          setTimeout(()=>{
            this.updateStep('step4')
          }, 1000);
          this.createExpressSearch()
        } else if (level === '1'){
          this.displayProfesionalLevels = true
        }
      },

      async createExpressSearch() {
        let data = { id: this.express_search_id, academic_level: this.academic_level, profesion: this.selected_profesional_level, ocr_payload: this.extracted_data, file: this.habeas_file }
        this.requestPost(this.url() + '/api/v1/express_searches', data).then(response => {
        if(response.data.success == true){
          this.express_search_id = response.data.data.express_search_id
        }else{
            return console.log("error -> " + JSON.stringify(response.data.message));
          }
        }, (err) => {
          return console.log('err users -> ' +  JSON.stringify(err))
        })
      },

      async callOcr() {
        const formData = new FormData()
        formData.append('name', this.habeas_file.name)
        formData.append('file', this.$refs.file.files[0])
        this.processing_habeas = true
        this.serverResponse = false
        this.errors_habeas = []
        this.extracted_data = null
        this.timeout_error = false

        this.requestPostTimeOut(this.url() + '/api/v1/express_searches/ocr', formData, 40000).then(response => {
          if(response.data.success == true){
              this.set_ocr_findings(response.data.data)
              this.processing_habeas = false
              this.serverResponse = true
          }else{
              this.$refs.file.value = null
              this.processing_habeas = false
              this.serverResponse = true
              return console.log("error -> " + JSON.stringify(response.data.message));
          }
        }, (err) => {
          if (err.code === 'ECONNABORTED') {
            this.processing_habeas = false
            this.timeout_error = true
            this.reset_step1()
            setTimeout(()=>{
              this.timeout_error = false
            }, 8000);
          }
          this.serverError = true
          return console.log('err users -> ' +  JSON.stringify(err))
        })
      },

      displayInfoModal(){
        this.popupActive = true
      },

      setProgress(value){
        this.progress = value
      },

      reload(){
        this.serverError = false,
        this.serverResponse = false,
        this.timeout_error = false,
        this.step1 = true,
        this.step2 = false,
        this.step3 = false,
        this.step4 = false,
        this.progress = 0,
        this.chekc_one = false,
        this.chekc_two = false,
        this.chekc_three = false,
        this.popupActive = false,
        this.processing_habeas = false,
        this.habeas_file = null,
        this.all_info_extracted = false,
        this.extracted_data = null,
        this.express_search_id = null,
        this.errors_habeas = [],
        this.wrong_data = false,
        this.academic_level = null,
        this.displayProfesionalLevels = false,
        this.selected_profesional_level = null
        this.$refs.file.value = null
      }

    },

    created(){
    },

  }
</script>

<style lang="scss">
  .steps-page {
    padding: 2% 0%;
    color: #634871;
    font-size: 15px;
  }

  .current-step {
    color: #5F008F;
    font-weight: 700;
  }

  .step-title {
    color: #5F008F;
    font-weight: 600;
    padding-bottom: 1.5%;
  }

  .step-body {
    color: #3C1A4E;
    padding: 2% 0 1% 0;
  }

  .step-container {
    background: #F5F0FF;
    border-radius: 16px;
    width: 60%;
    height: 100%;
    padding: 2% 1% !important;
    min-height: 200px;
  }

  .step-row {
    min-height: 200px;
  }

  .load-input{
    background: #FFFFFF;
    width: 100%;
    padding: 1% 1%;
    border-radius: 8px;
    color: #3C1A4E;
    cursor: pointer;
  }

  .error-message{
    color: #EA5455;
  }

  .errors-list{
    padding: 1% 0%;
  }

  .reload-btn{
    font-weight: 600;
    margin: 1% 0%;
    width: 100%;
  }

  .info-btn {
    background-color: #FFFFFF !important;
    color: #7412A6;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 4% 5%;
    border: none;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
  }

  .center-all {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center-vertical {
    display: flex;
    align-items: center;
  }


  .load-habeas{
    display: flex;
    align-items: center;
    background: #F5F0FF;
    width: 100%;
    height: 100%;
    padding: 1% 2%;
    border-radius: 8px;
  }

  .checked-habeas{
    background: #F5F0FF;
    width: 100%;
    padding: 1% 2%;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .habeas-loading-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 8px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 1%;
    color: #5F008F;
  }

  .habeas-succeed-text{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 1%;
    color: #00AF50;
  }

  .habeas-file-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 1%;
    color: #0D1A26;
  }

  .spinner-backgound {
    width: 35px;
    height: 35px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .person_name {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C1A4E;
  }

  .person_name_box{
    border-radius: 8px ;
    border: 1px solid #AEAED3;
    padding: 2% 4%;
    min-height: 100%;
  }

  .cofirmation_box{
    border-radius: 8px ;
    padding: 2% 4%;
    background-color: #FFFFFF;
    min-height: 100%;
  }

  .confirmation-icon-backgorund {
    border-radius: 50%;
    border: 1px solid #5F008F40;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .confirmation-icon-backgorund:hover {
    background-color: #F5F0FF;
  }

  .wrong-person {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #EA5455;
    padding: 1% 1%;
    display: flex;
    align-items: center;
  }

  .right-person {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #00AF50;
    padding: 1% 0;
  }

  .step3-body-card {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #634871;

    &-title {
      font-weight: 600;
    }
  }

  .radio-academic-level{
    background-color: #5F008F !important;
    color: #5F008F;
    border: 1px solid #5F008F;
  }

  .select-levels {
    border-color: #5F008F;
    border-radius: 5px;
    width: 100%;
    height: 1.7rem;
    font-family: Montserrat;
    font-size: 12px;
  }

  .step4-card-text{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.5% 0;
    color: #3C1A4E;
  }

  .banners {
    max-width: 100%; /* or any custom size */
    max-height: 170px;
    object-fit: cover;
  }

  .border-card {
    border: 1px solid #5F008F;
    border-radius: 5px;;
  }

  .timeout-error {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    padding: 2% 0;
    color: #EA5455;
  }

  .loader-bar-text{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C1A4E;
  }

  .loader-bar{
    width: 100%;
    margin: 4% 0;
  }

  #loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #5F008F;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

</style>
