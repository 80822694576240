<template>
  <div class="vx-row w-full">
    <div class="vx-col w-full">
      <div class="fatal-error-container">
        <p class="fatal-error-title">¡Ups! Parece que hay un problema con su conexión</p>
        <img src="../assets/images/errors/fatal-error-1.png" class=""/>
        <p class="fatal-error-message">Estamos experimentando intermitencias en la obtención de la información con la consulta exprés Intenta de nuevo o vuelve más tarde.</p>
        <vs-button class="fatal-error-btn-error" @click="refresh()">Realizar Consulta de nuevo</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ['refreshPage'],

    data() {
      return {}
    },

    methods: {
      refresh() {
        this.$emit("refreshPage");
      }
    }
  }
</script>

<style lang="scss">
  .fatal-error {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    &-title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #5F008F;
    }

    &-message {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #EA5455;
      padding: 20px 0px;
    }

    &-btn-error{
      font-family: Montserrat;
      text-align: center;
      font-weight: 700;
      color: white;
      background-color: #5F008F;
    }

  }
</style>
