var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serverError
    ? _c(
        "div",
        [
          _c("fatal-error", {
            on: {
              refreshPage: function ($event) {
                return _vm.refresh()
              },
            },
          }),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "express-search-section" },
          [
            _c(
              "div",
              {
                staticClass: "vx-row",
                staticStyle: { "justify-content": "space-between" },
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "vx-col",
                    staticStyle: {
                      width: "50%",
                      display: "flex",
                      "justify-content": "end",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "download-template",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        attrs: {
                          href: _vm.downloadHabeasTemplate(),
                          target: "_blank",
                          download: "",
                        },
                      },
                      [
                        _vm._v("\n          Plantilla Habeas Data  "),
                        _c("img", {
                          staticClass: "icon-small",
                          attrs: {
                            src: require("./../../assets/images/icons/download-icon.png"),
                          },
                        }),
                      ]
                    ),
                    _vm._m(1),
                  ]
                ),
              ]
            ),
            _c("express-search-form"),
          ],
          1
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col " }, [
      _c("h3", { staticClass: "header-express" }, [
        _vm._v("Consulta exprés "),
        _c("span", { staticClass: "beta" }, [_vm._v("Beta")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "print-page-disabled",
        staticStyle: { display: "flex", "align-items": "center" },
      },
      [
        _vm._v("\n          Imprimir consulta  "),
        _c("img", {
          staticClass: "icon-small",
          attrs: {
            src: require("./../../assets/images/icons/pdf-icon-second.png"),
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }