var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-section" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v("¿Cómo subir un archivo valido?"),
      ]),
      _c("p", { staticClass: "text" }, [
        _vm._v("Para subir un archivo valido de Habeas Data "),
        _c("span", { staticClass: "text-bold" }, [
          _vm._v(
            "descarga nuestra plantilla en la parte superior de la funcionalidad de consulta exprés"
          ),
        ]),
        _vm._v(" como se ve en la siguiente imagen."),
      ]),
      _c("img", {
        staticClass: "image-helper",
        attrs: {
          src: require("./../../assets/images/express-search/helper_template.png"),
        },
      }),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "Al descargar la plantilla en tu navegador, pídele a tu candidato que la firme y adjúntala en el modulo de Habeas Data."
        ),
      ]),
      _c("br"),
      _c("hr"),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "Por otro lado, si ya tienes la firma del candidato en otro archivo de Habeas Data asegúrate de que cumpla con las siguientes condiciones."
        ),
      ]),
      _c("div", { staticClass: "inner-section" }, [
        _c("p", { staticClass: "text" }, [
          _c("span", { staticClass: "text-bold" }, [
            _vm._v("1. Archivo correcto:"),
          ]),
          _vm._v(" Asegúrate de que sea formato .PDF o .JPG"),
        ]),
        _c("p", { staticClass: "text" }, [
          _c("span", { staticClass: "text-bold" }, [
            _vm._v("2. Buena calidad de imagen:"),
          ]),
          _vm._v(
            " Asegúrate de que la imagen debe tener una resolución suficiente para que los caracteres sean claramente distinguibles"
          ),
        ]),
        _c("p", { staticClass: "text" }, [
          _c("span", { staticClass: "text-bold" }, [
            _vm._v("3. Tipo y Fuente del Texto:"),
          ]),
          _vm._v(
            " Utiliza una fuente estándar y legible, como Arial, Times New Roman o Calibri. Además el tamaño del texto debe ser lo suficientemente grande (12 pt en adelante) para que los caracteres sean claramente visibles y reconocibles."
          ),
        ]),
        _c("p", { staticClass: "text" }, [
          _c("span", { staticClass: "text-bold" }, [
            _vm._v("4. Orientación y Alineación:"),
          ]),
          _vm._v(
            " El texto debe estar orientado correctamente en la imagen para garantizar una detección precisa de los caracteres."
          ),
        ]),
        _c("p", { staticClass: "text" }, [
          _c("span", { staticClass: "text-bold" }, [_vm._v("5. Contenido:")]),
          _vm._v(
            " Asegúrate de que el texto sea claro y tenga la siguiente información:"
          ),
        ]),
        _c("div", { staticClass: "inner-section" }, [
          _c("span", { staticClass: "text" }, [
            _vm._v("- Nombre completo del candidato"),
          ]),
          _c("br"),
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "- Documento de identidad del candidato que sea la cédula, únicamente números mínimo"
            ),
          ]),
          _c("br"),
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "- Autorizo a la empresa GLOBALWORK COLOMBIA S.A.S y/o su funcionario asignado que se realice validación de mis antecedentes legales, disciplinarios, laborales, académicos la Planilla Integrada de Liquidación de Aportes PILA de operadores autorizados"
            ),
          ]),
          _c("br"),
          _c("span", { staticClass: "text" }, [
            _vm._v("- Verificar que el documento contenga una firma"),
          ]),
          _c("br"),
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "- Verificar que el documento contenga fecha no más lejana de 30 días atrás"
            ),
          ]),
          _c("br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }