var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "dashboard-analytics" } },
    [
      _c("global-events", { attrs: { user: this.userInfo.user } }),
      _c(
        "div",
        { staticClass: "vx-row w-full lg:w-1/1 mb-base" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c(
                "div",
                { staticClass: "create-project-container" },
                [
                  _c("h2", { staticClass: "card-title" }, [
                    _vm._v("Crear proceso"),
                  ]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      "Te permitirá eligir los servicios que requieres para tomar las mejores decisiones al momento de gestionar el talento humano de tu organización."
                    ),
                  ]),
                  _c(
                    "vs-button",
                    {
                      attrs: { id: "btnCreateProject", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showForm()
                        },
                      },
                    },
                    [_vm._v("Crear proceso")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex banner",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c(
                "carousel",
                {
                  staticClass: "w-full",
                  attrs: {
                    "per-page": 1,
                    "mouse-drag": false,
                    autoplayTimeout: 8000,
                    scrollPerPage: true,
                    paginationActiveColor: "#FFC057",
                    paginationPadding: 10,
                    paginationSize: 7,
                    paginationPosition: "bottom-overlay",
                    navigationClickTargetSize: 4,
                    autoplay: "",
                    loop: "",
                  },
                },
                _vm._l(_vm.bannerInfo.data, function (banner) {
                  return _c(
                    "slide",
                    { key: banner.id, staticClass: "carousel-container" },
                    [
                      banner.redirection_url
                        ? _c("img", {
                            staticClass: "slide-carousel",
                            attrs: { src: banner.url },
                            on: {
                              click: function ($event) {
                                return _vm.navigateTo(
                                  banner.redirection_url,
                                  _vm.userInfo.user.client_id,
                                  _vm.userInfo.user.id,
                                  banner.id
                                )
                              },
                            },
                          })
                        : _c("img", {
                            staticClass: "slide-carousel",
                            staticStyle: { cursor: "default" },
                            attrs: { src: banner.url },
                          }),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-row w-full lg:w-1/1 mb-base" },
        [
          _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
            _c(
              "div",
              { staticClass: "fast-follow-container w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "btn-secondary",
                    class: _vm.isActive ? "btn-shadow" : "",
                    attrs: { color: "#fff" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleShadow("fastFollowing")
                      },
                    },
                  },
                  [_vm._v("Seguimiento rápido")]
                ),
                _vm.userInfo.user.express_search_enabled
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "btn-secondary",
                        class: _vm.isActive ? "" : "btn-shadow",
                        attrs: { color: "#fff" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleShadow("stateFollowing")
                          },
                        },
                      },
                      [
                        _vm._v("Consulta exprés "),
                        _c("span", { staticClass: "beta" }, [_vm._v("Beta")]),
                      ]
                    )
                  : _c(
                      "vs-button",
                      {
                        staticClass: "btn-secondary",
                        class: _vm.isActive ? "" : "btn-shadow",
                        attrs: { color: "#fff" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleShadow("stateFollowing")
                          },
                        },
                      },
                      [_vm._v("Seguimiento por estados")]
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.switchTablePipeline
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/1 mb-base cont-traceability" },
            [_c("process-detail")],
            1
          )
        : _c("div", { staticClass: "vx-col w-full lg:w-1/1 mb-base" }, [
            _vm.userInfo.user.express_search_enabled
              ? _c("div", [_c("express-search-layout")], 1)
              : _c("div", [_c("project-status")], 1),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }