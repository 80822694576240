<template>
  <div v-if="serverError">
    <fatal-error @refreshPage="refresh()" />
  </div>
  <div v-else>
    <div class="express-search-section">
      <div class="vx-row" style="justify-content: space-between;">
        <div class="vx-col ">
          <h3 class="header-express">Consulta exprés <span class="beta">Beta</span></h3>
        </div>
        <div class="vx-col" style="width: 50%; display: flex; justify-content: end;">
          <a class="download-template" :href="downloadHabeasTemplate()" target="_blank" download  style="display: flex; align-items: center;">
            Plantilla Habeas Data &nbsp;<img src="./../../assets/images/icons/download-icon.png" class="icon-small">
          </a>
          <button class="print-page-disabled" style="display: flex; align-items: center;">
            Imprimir consulta &nbsp;<img src="./../../assets/images/icons/pdf-icon-second.png" class="icon-small">
          </button>
        </div>
      </div>

      <express-search-form />
    </div>
  </div>
</template>

<script>
  import api from '../../mixins/api.js'
  import FatalError from '../FatalError.vue'
  import ExpressSearchForm from './ExpressSearchForm.vue'

  export default {
    mixins: [api],

    data(){
      return {
        serverError: false,
      }
    },

    components: {
      'fatal-error': FatalError,
      'express-search-form': ExpressSearchForm
    },

    methods: {
      refresh() {
        this.serverError = false
        this.callServer()
      },

      downloadHabeasTemplate(){
        console.log('Hi!')
        return "https://globalwork-production.s3.amazonaws.com/Pdf/plantilla-habeas.pdf"
      },

      callServer() {
        this.requestGetWithoutContent(this.url() + '/api/v1/express_searches/new', {}).then(response => {
          if(response.data.success == true){
            return console.log('Oka')
          }else{
            return console.log("error -> " + JSON.stringify(response.data.message));
          }
        }, (err) => {
          this.serverError = true
          return console.log('err users -> ' +  JSON.stringify(err))
        })
      }
    },

    created(){
      this.callServer()
    },

  }
</script>

<style lang="scss">
  .express-search-section{
    padding: 0 1%;
  }

  .header-express {
    color: #5F008F;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    text-align: left;
  }

  .download-template {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F008F;
    border: 2px solid #5F008F;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 1% 4%;
    margin: 0 1%;
  }

  .download-template:hover {
    background-color: #F5F0FF;
    cursor: pointer;
  }

  .print-page-disabled {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #AEAED3;
    border: 2px solid #F2EDFC;
    border-radius: 5px;
    background-color: #F2EDFC;
    padding: 1% 4%;
    margin: 0 1%;
  }

  .icon-small {
    width: 16px;
  }
</style>
