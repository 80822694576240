<template>

  <div>
    <div v-if="scrappers.fosyga.error === false" class="vx-row">
      <div class="vx-col" style="width: 100%;">

        <div v-if="scrappers.fosyga.finished"  class="general-section">
          <p class="general-section-text">Esta cédula pertenece a:</p>
          <div class="center-vertical" style="padding: 0;">
            <img v-if="scrappers.fosyga.extra !== null && scrappers.fosyga.extra.verified_name" src="./../../assets/images/icons/green-check.png" style="width:20px;"/>&nbsp;&nbsp;&nbsp;
            <span class="general-section-title">{{ this.$props.name }}</span>
          </div>

          <div class="vx-row">

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection">
                <p class="general-subsection-title">Número de documento</p>
                <p class="general-subsection-subtitle">CC {{ this.$props.identification_number }}</p>
                <div v-if="scrappers.rnec.extra !== null && scrappers.rnec.extra.col_nit" class="center-vertical">
                  <img src="../../assets/images/fast-tracking/utils/checks/purple-check.svg" style="width:12px;"/>&nbsp;&nbsp;
                  <span class="general-subsection-check">Cédula registrada en Colombia</span>
                </div>
              </div>
            </div>

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection">
                <p class="general-subsection-title">EPS</p>
                <p class="general-subsection-subtitle"><span v-if="scrappers.fosyga.extra !== null && scrappers.fosyga.extra.has_eps">{{ scrappers.fosyga.extra.eps }}</span></p>
                <div v-if="scrappers.fosyga.extra !== null && scrappers.fosyga.extra.eps_status" class="center-vertical">
                  <img src="../../assets/images/fast-tracking/utils/checks/purple-check.svg" style="width:12px;"/>&nbsp;&nbsp;
                  <span class="general-subsection-check">Activo</span>
                </div>
              </div>
            </div>

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection">
                <p class="general-subsection-title">Libreta Militar</p>
                <p class="general-subsection-subtitle">Activa</p>
                <span class="general-subsection-check">&nbsp;</span>
              </div>
            </div>

          </div>

        </div>
        <div v-else class="general-section-loader">

          <div class="general-subsection-bar-title" style="width: 25%;"></div>

          <div class="vx-row">

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection-loader">
                <p class="general-subsection-bar" style="width: 30%;"></p>
                <p class="general-subsection-bar"></p>
              </div>
            </div>

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection-loader">
                <p class="general-subsection-bar" style="width: 30%;"></p>
                <p class="general-subsection-bar"></p>
              </div>
            </div>

            <div class="vx-col" style="width: 30%;">
              <div class="general-subsection-loader">
                <p class="general-subsection-bar" style="width: 30%;"></p>
                <p class="general-subsection-bar"></p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div v-if="(scrappers.fosyga.extra === null) || (scrappers.fosyga.extra.verified_name === true)" class="vx-row">
    <!-- <div v-if="false" class="vx-row"> -->
      <div class="vx-col" style="width: 100%;">
        <div class="section-title">
          <span>Antecedentes</span>
        </div>

        <div class="vx-row">
          <div class="vx-col" style="width: 5%;">
          </div>
          <div class="vx-col" style="width: 95%;" @click="toggleScraperList('national')">
            <div v-if="false" class="section-title">
              <div class="vx-row">
                <div class="vx-col center-vertical" style="width: 100%;">
                  <div class="general-loader-circle"></div>
                  <div class="general-loader-bar" style="width: 25%;"></div>
                </div>
              </div>
            </div>
            <div v-else class="section-title center-vertical">
              <div class="vx-row">
                <div class="vx-col center-vertical" style="width: 5%;">
                  <img src="./../../assets/images/icons/green-check.png" style="width:20px;"/>&nbsp;&nbsp;&nbsp;
                </div>
                <div class="vx-col center-vertical" style="width: 85%;">
                  <span>Lista Nacional</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transition name="slide">
          <div v-if="show_scrapper_list === 'national'">
            <div v-for="(scrapper, key) in scrappers" :key="key" class="vx-row">
              <div v-if="scrapper.country === 'co'" class="vx-col" style="width: 100%;">

                <div v-if="scrapper.error === false">
                  <div v-if="scrapper.finished" class="scrapper-result-box">
                    <div class="vx-col">
                      <p class="scrapper-result-box-title">{{ scrapper.name }}</p>
                      <p>{{ scrapper.result }}</p>
                      <div v-if="scrapper.warning === 'true' || scrapper.warning === true">
                        <p style="text-decoration: underline; cursor: pointer;" @click="scrapper.displayImage = !scrapper.displayImage" >{{ scrapper.displayImage ? 'Ocultar Imagen' : 'Ver Imagen' }}</p>
                          <Transition name="slide">
                            <div v-if="scrapper.displayImage">
                              <a :href="scrapper.url" target="_blank">
                              <img :src="scrapper.url" style="width:60%; margin: 1% 0;"/>
                              </a>
                            </div>
                          </Transition>
                      </div>
                    </div>
                    <div class="vx-col center-all">
                      <img v-if="scrapper.warning === 'true' || scrapper.warning === true" src="./../../assets/images/icons/wrong-circle.png" style="width:20px;"/>
                      <img v-else src="./../../assets/images/icons/green-check.png" style="width:20px;"/>
                    </div>
                  </div>
                  <div v-else class="scrapper-result-box-loader center-vertical">
                      <div class="vx-col center-vertical" style="width: 100%;">
                        <div class="general-loader-circle"></div>
                        <div class="general-loader-bar" style="width: 30%;"></div>
                      </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="mainSources(key)" class="scrapper-result-box">
                    <div class="vx-col">
                      <p class="error-message">Te informamos que la fuente de <strong>{{ scrapper.name }}</strong> está experimentando intermitencias en la obtención de la información de los antecedentes.
                        Estamos tomando todas las acciones necesarias para reestablecer el servicio.</p>
                    </div>
                    <div class="vx-col center-all">
                      <img src="./../../assets/images/icons/wrong-circle.png" style="width:20px;"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </Transition>

        <div class="vx-row">
          <div class="vx-col" style="width: 5%;">
          </div>
          <div class="vx-col" style="width: 95%;" @click="toggleScraperList('international')">
            <div v-if="false" class="section-title">
              <div class="vx-row">
                <div class="vx-col center-vertical" style="width: 100%;">
                  <div class="general-loader-circle"></div>
                  <div class="general-loader-bar" style="width: 25%;"></div>
                </div>
              </div>
            </div>
            <div v-else class="section-title center-vertical">
              <div class="vx-row">
                <div class="vx-col center-vertical" style="width: 5%;">
                  <img src="./../../assets/images/icons/green-check.png" style="width:20px;"/>&nbsp;&nbsp;&nbsp;
                </div>
                <div class="vx-col center-vertical" style="width: 85%;">
                  <span>Lista Internacional</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transition name="slide">
          <div v-if="show_scrapper_list === 'international'">
            <div v-for="(scrapper, index) in scrappers" :key="index" class="vx-row">
              <div v-if="scrapper.country === 'all' && scrapper.error === false" class="vx-col" style="width: 100%;">

                <div v-if="scrapper.finished" class="scrapper-result-box">
                  <div class="vx-col">
                    <p class="scrapper-result-box-title">{{ scrapper.name }}</p>
                    <p>{{ scrapper.result }}</p>
                    <div v-if="scrapper.warning === 'true' || scrapper.warning === true">
                      <p style="text-decoration: underline; cursor: pointer;" @click="scrapper.displayImage = !scrapper.displayImage" >{{ scrapper.displayImage ? 'Ocultar Imagen' : 'Ver Imagen' }}</p>
                        <Transition name="slide">
                          <div v-if="scrapper.displayImage">
                            <a :href="scrapper.url" target="_blank">
                            <img :src="scrapper.url" style="width:60%; margin: 1% 0;"/>
                            </a>
                          </div>
                        </Transition>
                    </div>
                  </div>
                  <div class="vx-col center-all">
                    <img v-if="scrapper.warning === 'true' || scrapper.warning === true" src="./../../assets/images/icons/wrong-circle.png" style="width:20px;"/>
                    <img v-else src="./../../assets/images/icons/green-check.png" style="width:20px;"/>
                  </div>
                </div>
                <div v-else class="scrapper-result-box-loader center-vertical">
                    <div class="vx-col center-vertical" style="width: 100%;">
                      <div class="general-loader-circle"></div>
                      <div class="general-loader-bar" style="width: 30%;"></div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </Transition>

      </div>
    </div>
    <div v-else>
      <div class="vx-row">
        <div class="vx-col" style="width: 100%;">

          <div class="general-section">
            <div class="vx-col center-all" style="flex-grow: 1; width:100% ;margin: 1% 0;">
              <img src="./../../assets/images/express-search/wrong-person.png" class="banners"/>
            </div>
            <div class="vx-col center-all" style="flex-grow: 1; width:100% ;margin: 1% 0; text-align: center;">
              <p class="error-message">Parece que la cédula que ingresaste pertenece a otra persona, según la información validada con FOSYGA. <br>
                <strong>Verifica la cédula ingresada en el Habeas Data y cargarla nuevamente.</strong>
              </p>
            </div>
            <div class="vx-col center-all" style="flex-grow: 1; width:100%; margin: 1% 0; text-align: center;">
              <vs-button class="reload-btn" @click="reset()" style="width: 40%;">
                Realizar consulta de nuevo
              </vs-button>
            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
  import api from '../../mixins/api.js'

  export default {
    mixins: [api],

    props: {
        name: {
          type: String,
          required: true
        },
        express_search_id: {
          type: Number,
          required: true
        },
        identification_number: {
          type: String,
          required: true
        },
        academic_level: {
          type: String,
          required: true,
        },
        profesional_level: {
          type: String,
        }
    },

    data() {
      return {
        requestCounter: 0,
        interval: null,
        stopConsulting: false,
        show_scrapper_list: '',
        scrappers: {
          rnec: {
            name: 'Registraduría Nacional',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          procuraduria: {
            name: 'Procuradoría',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          judicial_processes: {
            name: 'Rama Judicial',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          police: {
            name: 'Policia Nacional',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          militar: {
            name: 'Militar',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          simit: {
            name: 'SIMIT',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          contraloria: {
            name: 'Contraloría',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          rues: {
            name: 'RUES',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          simur: {
            name: 'Consulta Simur',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          fosyga: {
            name: 'Fosyga',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          presidencial: {
            name: 'Lista negra presidencial Listados Internacionales:',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'co',
            extra: null,
            url: null,
            displayImage: false
          },
          dea: {
            name: 'DEA',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          fbi: {
            name: 'FBI Más Buscados',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          fbi_fugitives: {
            name: 'FBI Fugitivos',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          fbi_terrorism: {
            name: 'FBI Terrorismo',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          fbi_banks: {
            name: 'FBI Bancos',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          fbi_vicap: {
            name: 'FBI Crimenes Violentos y Sexuales',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          marshals: {
            name: 'Marsshals Fugitivos',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            url: null,
            displayImage: false
          },
          marshalsall: {
            name: 'Marshals Más Buscados',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          interpol: {
            name: 'INTERPOL',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          ofac: {
            name: 'OFAC (Lista Clinton)',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          bdi: {
            name: 'Banco Interamericano del Desarrollo / Corruptos',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          onu: {
            name: 'Sanciones ONU',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          uktreasury: {
            name: 'Tesoro del Reino Unido',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
          eusanctions: {
            name: 'Sancionados Union Europea',
            result: '',
            warning: '',
            finished: false,
            error: false,
            country: 'all',
            extra: null,
            url: null,
            displayImage: false
          },
        }
      }
    },
    components: {
    },

    methods: {

      toggleScraperList(value){
        if(this.show_scrapper_list === value){
          this.show_scrapper_list = ''
        } else if (this.show_scrapper_list === 'international' && value === 'national'){
          this.show_scrapper_list = 'national'
        } else if(this.show_scrapper_list === 'national' && value === 'international'){
          this.show_scrapper_list = 'international'
        } else {
          this.show_scrapper_list = value
        }
      },

      requestInterval() {
        this.interval = setInterval(() => {
          if (this.requestCounter < 24) {
            this.requestCounter += 1
            this.getScraperResults()
          } else {
            this.setNotResponse()
            clearInterval(this.interval);
          }
          this.updateProgress()
        }, 10000)
      },

      async getScraperResults() {
        let data = { express_search_id: this.$props.express_search_id }
        this.requestPost(this.url() + '/api/v1/express_searches/scraper_response', data).then(response => {
          if(response.data.success == true){
            this.setResults(response.data.data)
          }else{
              return console.log("error -> " + JSON.stringify(response.data.message));
          }
        }, (err) => {
          return console.log('err users -> ' +  JSON.stringify(err))
        })
      },

      setResults(data){
        data.forEach((item) => {
          if (this.scrappers.hasOwnProperty(item.name)) {
            this.scrappers[item.name].result = item.result;
            this.scrappers[item.name].warning = item.warning;
            this.scrappers[item.name].finished = item.done;
            this.scrappers[item.name].extra = item.extra;
            this.scrappers[item.name].url = item.url;
          }
        });
        this.updateProgress()
      },

      setNotResponse(){
        Object.entries(this.scrappers).forEach(([key, value]) => {
          if (!value.finished) {
            value.error = true;
          }
        });
        this.updateProgress()
        this.stopConsulting = true
      },

      updateProgress() {
        let progress_value = 0
        if (this.stopConsulting) {
          progress_value = 25
        } else {
          Object.entries(this.scrappers).forEach(([key, value]) => {
            if (value.finished) {
              progress_value += 1
            }
          });
        }
        this.$emit('progress', progress_value)
      },

      mainSources(scraper_name){
        const main = ["fosyga", "police", "judicial_processes", "contraloria", "procuraduria"]
        return main.includes(scraper_name)
      },

      reset(){
        this.loader = false,
        this.requestCounter = 0,
        this.interval = null,
        this.stopConsulting = false,
        this.show_scrapper_list = ''
        this.$emit('reset')
      },

      async initiateScrapers() {
        let data = {
                      express_search_id: this.express_search_id,
                      id: this.identification_number,
                      name: this.name,
                      academic_level: this.academic_level,
                      profesional_level: this.profesional_level
                    }
        this.requestPost(this.url() + '/api/v1/express_searches/execute_scraper', data).then(response => {
          if(response.data.success == true){
            this.requestInterval()
          }else{
            return console.log("error -> " + JSON.stringify(response.data.message));
          }
        }, (err) => {
          return console.log('err users -> ' +  JSON.stringify(err))
        })
      },

    },

    created(){
      this.initiateScrapers()
    },

  }
</script>

<style lang="scss">

  .general-section {
    padding: 2% 2%;
    border-radius: 8px;
    border: 1px solid #ECC9FF;
    color: #634871;
    margin-bottom: 2%;
    min-height: 174px;

    &-text {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 600;
    }
    &-title {
      color: #5F008F;
      font-size: 20px;
      font-weight: 600;
      padding: 1% 0;
    }
  }

  .general-section-loader {
    padding: 2% 2%;
    border-radius: 8px;
    border: 1px solid #ECC9FF;
    color: #634871;
    margin-bottom: 2%;
    min-height: 174px;
  }

  .general-subsection {
    background-color: #F2EDFC;
    padding: 2% 2%;
    font-family: Montserrat;
    font-size: 14px;
    border-radius: 8px;
    min-height: 78px;

    &-title {
      font-weight: 600;
      font-size: 14px;
      color: #3C1A4E;
    }
    &-subtitle {
      font-weight: 400;
      font-size: 14px;
      color: #3C1A4E;
    }
    &-check {
      font-weight: 600;
      font-size: 12px;
      color: #5F008F;
    }
  }

  .general-subsection-loader {
    background-color: #F2EDFC;
    padding: 2% 2%;
    border-radius: 8px;
    min-height: 75px;
  }

  .general-subsection-bar-title {
    background-color: #AEAED3;
    padding: 0.7%;
    margin: 0.7% 0%;
    border-radius: 8px;
    height: 16px;

    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .general-loader-circle {
    background-color: #AEAED3;
    border-radius: 50%;
    width: 1.2vw;
    height: 1.2vw;

    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .general-loader-bar {
    background-color: #AEAED3;
    border-radius: 8px;
    margin: 0 1%;
    height: 16px;
    width: 80%;

    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .general-subsection-bar {
    background-color: #AEAED3;
    padding: 2% 2%;
    margin: 3%;
    border-radius: 8px;
    min-height: 18px;

    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  }

  .section-title {
    background-color: #F2EDFC;
    border-radius: 8px;
    padding: 1%;
    margin: 1% 0;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
    color: #3C1A4E;
  }

  .scrapper-result-box {
    border: 1px solid #ECC9FF;
    border-radius: 8px;
    color: #634871;
    padding: 1% 2%;
    margin: 0.5% 0;
    margin-left: 8%;
    display: flex;
    justify-content: space-between;
    min-height: 71px;

    &-title {
      font-weight: 600;
      font-size: 14px;
      color: #3C1A4E;
    }
  }

  .scrapper-result-box-loader {
    border: 1px solid #ECC9FF;
    border-radius: 8px;
    color: #634871;
    padding: 1% 2%;
    margin: 0.5% 0;
    margin-left: 8%;
    min-height: 71px;
  }
</style>
