var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.scrappers.fosyga.error === false
      ? _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col", staticStyle: { width: "100%" } }, [
            _vm.scrappers.fosyga.finished
              ? _c("div", { staticClass: "general-section" }, [
                  _c("p", { staticClass: "general-section-text" }, [
                    _vm._v("Esta cédula pertenece a:"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "center-vertical",
                      staticStyle: { padding: "0" },
                    },
                    [
                      _vm.scrappers.fosyga.extra !== null &&
                      _vm.scrappers.fosyga.extra.verified_name
                        ? _c("img", {
                            staticStyle: { width: "20px" },
                            attrs: {
                              src: require("./../../assets/images/icons/green-check.png"),
                            },
                          })
                        : _vm._e(),
                      _vm._v("   \n          "),
                      _c("span", { staticClass: "general-section-title" }, [
                        _vm._v(_vm._s(this.$props.name)),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col", staticStyle: { width: "30%" } },
                      [
                        _c("div", { staticClass: "general-subsection" }, [
                          _c("p", { staticClass: "general-subsection-title" }, [
                            _vm._v("Número de documento"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "general-subsection-subtitle" },
                            [
                              _vm._v(
                                "CC " +
                                  _vm._s(this.$props.identification_number)
                              ),
                            ]
                          ),
                          _vm.scrappers.rnec.extra !== null &&
                          _vm.scrappers.rnec.extra.col_nit
                            ? _c("div", { staticClass: "center-vertical" }, [
                                _c("img", {
                                  staticStyle: { width: "12px" },
                                  attrs: {
                                    src: require("../../assets/images/fast-tracking/utils/checks/purple-check.svg"),
                                  },
                                }),
                                _vm._v("  \n                "),
                                _c(
                                  "span",
                                  { staticClass: "general-subsection-check" },
                                  [_vm._v("Cédula registrada en Colombia")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col", staticStyle: { width: "30%" } },
                      [
                        _c("div", { staticClass: "general-subsection" }, [
                          _c("p", { staticClass: "general-subsection-title" }, [
                            _vm._v("EPS"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "general-subsection-subtitle" },
                            [
                              _vm.scrappers.fosyga.extra !== null &&
                              _vm.scrappers.fosyga.extra.has_eps
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.scrappers.fosyga.extra.eps)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.scrappers.fosyga.extra !== null &&
                          _vm.scrappers.fosyga.extra.eps_status
                            ? _c("div", { staticClass: "center-vertical" }, [
                                _c("img", {
                                  staticStyle: { width: "12px" },
                                  attrs: {
                                    src: require("../../assets/images/fast-tracking/utils/checks/purple-check.svg"),
                                  },
                                }),
                                _vm._v("  \n                "),
                                _c(
                                  "span",
                                  { staticClass: "general-subsection-check" },
                                  [_vm._v("Activo")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._m(0),
                  ]),
                ])
              : _c("div", { staticClass: "general-section-loader" }, [
                  _c("div", {
                    staticClass: "general-subsection-bar-title",
                    staticStyle: { width: "25%" },
                  }),
                  _vm._m(1),
                ]),
          ]),
        ])
      : _vm._e(),
    _vm.scrappers.fosyga.extra === null ||
    _vm.scrappers.fosyga.extra.verified_name === true
      ? _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col", staticStyle: { width: "100%" } },
            [
              _vm._m(2),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", {
                  staticClass: "vx-col",
                  staticStyle: { width: "5%" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "vx-col",
                    staticStyle: { width: "95%" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleScraperList("national")
                      },
                    },
                  },
                  [
                    false
                      ? _c("div", { staticClass: "section-title" }, [_vm._m(3)])
                      : _c(
                          "div",
                          { staticClass: "section-title center-vertical" },
                          [_vm._m(4)]
                        ),
                  ]
                ),
              ]),
              _c("Transition", { attrs: { name: "slide" } }, [
                _vm.show_scrapper_list === "national"
                  ? _c(
                      "div",
                      _vm._l(_vm.scrappers, function (scrapper, key) {
                        return _c("div", { key: key, staticClass: "vx-row" }, [
                          scrapper.country === "co"
                            ? _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  scrapper.error === false
                                    ? _c("div", [
                                        scrapper.finished
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "scrapper-result-box",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "vx-col" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "scrapper-result-box-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(scrapper.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(scrapper.result)
                                                      ),
                                                    ]),
                                                    scrapper.warning ===
                                                      "true" ||
                                                    scrapper.warning === true
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticStyle: {
                                                                  "text-decoration":
                                                                    "underline",
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      scrapper.displayImage =
                                                                        !scrapper.displayImage
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scrapper.displayImage
                                                                      ? "Ocultar Imagen"
                                                                      : "Ver Imagen"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "Transition",
                                                              {
                                                                attrs: {
                                                                  name: "slide",
                                                                },
                                                              },
                                                              [
                                                                scrapper.displayImage
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              href: scrapper.url,
                                                                              target:
                                                                                "_blank",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60%",
                                                                                  margin:
                                                                                    "1% 0",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: scrapper.url,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vx-col center-all",
                                                  },
                                                  [
                                                    scrapper.warning ===
                                                      "true" ||
                                                    scrapper.warning === true
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "20px",
                                                          },
                                                          attrs: {
                                                            src: require("./../../assets/images/icons/wrong-circle.png"),
                                                          },
                                                        })
                                                      : _c("img", {
                                                          staticStyle: {
                                                            width: "20px",
                                                          },
                                                          attrs: {
                                                            src: require("./../../assets/images/icons/green-check.png"),
                                                          },
                                                        }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "scrapper-result-box-loader center-vertical",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vx-col center-vertical",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "general-loader-circle",
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "general-loader-bar",
                                                      staticStyle: {
                                                        width: "30%",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ])
                                    : _c("div", [
                                        _vm.mainSources(key)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "scrapper-result-box",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "vx-col" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "error-message",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Te informamos que la fuente de "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scrapper.name
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " está experimentando intermitencias en la obtención de la información de los antecedentes.\n                      Estamos tomando todas las acciones necesarias para reestablecer el servicio."
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vx-col center-all",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "20px",
                                                      },
                                                      attrs: {
                                                        src: require("./../../assets/images/icons/wrong-circle.png"),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", {
                  staticClass: "vx-col",
                  staticStyle: { width: "5%" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "vx-col",
                    staticStyle: { width: "95%" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleScraperList("international")
                      },
                    },
                  },
                  [
                    false
                      ? _c("div", { staticClass: "section-title" }, [_vm._m(5)])
                      : _c(
                          "div",
                          { staticClass: "section-title center-vertical" },
                          [_vm._m(6)]
                        ),
                  ]
                ),
              ]),
              _c("Transition", { attrs: { name: "slide" } }, [
                _vm.show_scrapper_list === "international"
                  ? _c(
                      "div",
                      _vm._l(_vm.scrappers, function (scrapper, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            scrapper.country === "all" &&
                            scrapper.error === false
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vx-col",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    scrapper.finished
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "scrapper-result-box",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "scrapper-result-box-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scrapper.name)
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(scrapper.result)
                                                  ),
                                                ]),
                                                scrapper.warning === "true" ||
                                                scrapper.warning === true
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticStyle: {
                                                              "text-decoration":
                                                                "underline",
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                scrapper.displayImage =
                                                                  !scrapper.displayImage
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scrapper.displayImage
                                                                  ? "Ocultar Imagen"
                                                                  : "Ver Imagen"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "Transition",
                                                          {
                                                            attrs: {
                                                              name: "slide",
                                                            },
                                                          },
                                                          [
                                                            scrapper.displayImage
                                                              ? _c("div", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: scrapper.url,
                                                                        target:
                                                                          "_blank",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60%",
                                                                              margin:
                                                                                "1% 0",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: scrapper.url,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col center-all",
                                              },
                                              [
                                                scrapper.warning === "true" ||
                                                scrapper.warning === true
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        width: "20px",
                                                      },
                                                      attrs: {
                                                        src: require("./../../assets/images/icons/wrong-circle.png"),
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticStyle: {
                                                        width: "20px",
                                                      },
                                                      attrs: {
                                                        src: require("./../../assets/images/icons/green-check.png"),
                                                      },
                                                    }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "scrapper-result-box-loader center-vertical",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col center-vertical",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "general-loader-circle",
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "general-loader-bar",
                                                  staticStyle: { width: "30%" },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ])
      : _c("div", [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "general-section" }, [
                  _vm._m(7),
                  _vm._m(8),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col center-all",
                      staticStyle: {
                        "flex-grow": "1",
                        width: "100%",
                        margin: "1% 0",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "reload-btn",
                          staticStyle: { width: "40%" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              Realizar consulta de nuevo\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col", staticStyle: { width: "30%" } }, [
      _c("div", { staticClass: "general-subsection" }, [
        _c("p", { staticClass: "general-subsection-title" }, [
          _vm._v("Libreta Militar"),
        ]),
        _c("p", { staticClass: "general-subsection-subtitle" }, [
          _vm._v("Activa"),
        ]),
        _c("span", { staticClass: "general-subsection-check" }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col", staticStyle: { width: "30%" } }, [
        _c("div", { staticClass: "general-subsection-loader" }, [
          _c("p", {
            staticClass: "general-subsection-bar",
            staticStyle: { width: "30%" },
          }),
          _c("p", { staticClass: "general-subsection-bar" }),
        ]),
      ]),
      _c("div", { staticClass: "vx-col", staticStyle: { width: "30%" } }, [
        _c("div", { staticClass: "general-subsection-loader" }, [
          _c("p", {
            staticClass: "general-subsection-bar",
            staticStyle: { width: "30%" },
          }),
          _c("p", { staticClass: "general-subsection-bar" }),
        ]),
      ]),
      _c("div", { staticClass: "vx-col", staticStyle: { width: "30%" } }, [
        _c("div", { staticClass: "general-subsection-loader" }, [
          _c("p", {
            staticClass: "general-subsection-bar",
            staticStyle: { width: "30%" },
          }),
          _c("p", { staticClass: "general-subsection-bar" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-title" }, [
      _c("span", [_vm._v("Antecedentes")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass: "vx-col center-vertical",
          staticStyle: { width: "100%" },
        },
        [
          _c("div", { staticClass: "general-loader-circle" }),
          _c("div", {
            staticClass: "general-loader-bar",
            staticStyle: { width: "25%" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col center-vertical", staticStyle: { width: "5%" } },
        [
          _c("img", {
            staticStyle: { width: "20px" },
            attrs: {
              src: require("./../../assets/images/icons/green-check.png"),
            },
          }),
          _vm._v("   \n              "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "vx-col center-vertical",
          staticStyle: { width: "85%" },
        },
        [_c("span", [_vm._v("Lista Nacional")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass: "vx-col center-vertical",
          staticStyle: { width: "100%" },
        },
        [
          _c("div", { staticClass: "general-loader-circle" }),
          _c("div", {
            staticClass: "general-loader-bar",
            staticStyle: { width: "25%" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col center-vertical", staticStyle: { width: "5%" } },
        [
          _c("img", {
            staticStyle: { width: "20px" },
            attrs: {
              src: require("./../../assets/images/icons/green-check.png"),
            },
          }),
          _vm._v("   \n              "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "vx-col center-vertical",
          staticStyle: { width: "85%" },
        },
        [_c("span", [_vm._v("Lista Internacional")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "vx-col center-all",
        staticStyle: { "flex-grow": "1", width: "100%", margin: "1% 0" },
      },
      [
        _c("img", {
          staticClass: "banners",
          attrs: {
            src: require("./../../assets/images/express-search/wrong-person.png"),
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "vx-col center-all",
        staticStyle: {
          "flex-grow": "1",
          width: "100%",
          margin: "1% 0",
          "text-align": "center",
        },
      },
      [
        _c("p", { staticClass: "error-message" }, [
          _vm._v(
            "Parece que la cédula que ingresaste pertenece a otra persona, según la información validada con FOSYGA. "
          ),
          _c("br"),
          _c("strong", [
            _vm._v(
              "Verifica la cédula ingresada en el Habeas Data y cargarla nuevamente."
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }