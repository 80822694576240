var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "vx-row steps-page",
          staticStyle: { "justify-content": "start" },
        },
        [
          _c(
            "div",
            {
              staticClass: "vx-col",
              staticStyle: {
                width: "100%",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "span",
                { staticClass: "center-all" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "fade",
                        duration: { enter: 500, leave: 500 },
                      },
                    },
                    [
                      this.chekc_one
                        ? _c("img", {
                            staticStyle: { width: "16px" },
                            attrs: {
                              src: require("./../../assets/images/icons/green-check.png"),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "span",
                    { class: { "current-step": _vm.step1 === true } },
                    [_vm._v(" 1. Habeas Data ")]
                  ),
                  _vm._v(" | \n        "),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "center-all" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "fade",
                        duration: { enter: 500, leave: 500 },
                      },
                    },
                    [
                      this.chekc_two
                        ? _c("img", {
                            staticStyle: { width: "16px" },
                            attrs: {
                              src: require("./../../assets/images/icons/green-check.png"),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "span",
                    { class: { "current-step": _vm.step2 === true } },
                    [_vm._v(" 2. Documento ")]
                  ),
                  _vm._v(" | \n        "),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "center-all" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "fade",
                        duration: { enter: 500, leave: 500 },
                      },
                    },
                    [
                      this.chekc_three
                        ? _c("img", {
                            staticStyle: { width: "16px" },
                            attrs: {
                              src: require("./../../assets/images/icons/green-check.png"),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "span",
                    { class: { "current-step": _vm.step3 === true } },
                    [_vm._v(" 3. Nivel académico")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: { enter: 500, leave: 500 } } },
        [
          _vm.step1
            ? _c("div", [
                _c("div", { staticClass: "vx-row step-row" }, [
                  _c("div", { staticClass: "vx-col step-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vx-row",
                        staticStyle: { "justify-content": "space-between" },
                      },
                      [
                        _c("div", { staticClass: "vx-col" }, [
                          _c("h3", { staticClass: "step-title" }, [
                            _vm._v("1. Habeas Data"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col",
                            staticStyle: { width: "30%" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "info-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.displayInfoModal()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  ¿Cómo subir un archivo válido?\n                "
                                ),
                              ]
                            ),
                            _c(
                              "vs-popup",
                              {
                                attrs: { title: "", active: _vm.popupActive },
                                on: {
                                  "update:active": function ($event) {
                                    _vm.popupActive = $event
                                  },
                                },
                              },
                              [_c("info-modal")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    this.serverResponse
                      ? _c("div", [
                          this.errors_habeas.length > 0
                            ? _c("div", { staticClass: "errors-list" }, [
                                _c("div", { staticClass: "vx-row" }, [
                                  _c("div", { staticClass: "vx-col" }, [
                                    _c("img", {
                                      staticStyle: { width: "16px" },
                                      attrs: {
                                        src: require("./../../assets/images/icons/wrong-circle.png"),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "vx-col" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              "Verificación no exitosa del documento."
                                            ),
                                          ]),
                                          _vm._v(
                                            " El documento no cuenta con:"
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.errors_habeas,
                                        function (error, index) {
                                          return _c("ul", { key: error }, [
                                            _c(
                                              "li",
                                              { staticClass: "error-message" },
                                              [
                                                _vm._v(
                                                  _vm._s(index + 1) +
                                                    ". " +
                                                    _vm._s(error)
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-row",
                                    staticStyle: {
                                      "justify-content": "center",
                                      padding: "1% 0%",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vx-col",
                                        staticStyle: { width: "50%" },
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "reload-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.reset_step1()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Subir Habeas Data de nuevo\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _c("div", [
                                this.all_info_extracted
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "checked-habeas" },
                                        [
                                          _c("img", {
                                            staticStyle: { width: "16px" },
                                            attrs: {
                                              src: require("./../../assets/images/icons/green-check.png"),
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "habeas-succeed-text",
                                            },
                                            [_vm._v(" Documento comprobado")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checked-habeas" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "spinner-backgound",
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: { width: "16px" },
                                                attrs: {
                                                  src: require("./../../assets/images/icons/pdf-icon.png"),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "habeas-file-name" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(this.habeas_file.name)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                        ])
                      : _c("div", [
                          _vm.processing_habeas
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "vx-row center-all" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vx-col",
                                        staticStyle: {
                                          width: "100%",
                                          "padding-top": "2.5%",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "load-habeas" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "spinner-backgound",
                                              },
                                              [
                                                _c("span", {
                                                  attrs: { id: "loading" },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "habeas-loading-text",
                                              },
                                              [
                                                _vm._v(
                                                  "Confirmando Habeas Data..."
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                [
                                  _c("p", { staticClass: "step-body" }, [
                                    _vm._v(
                                      "\n                  Adjunta el Habeas Data de la persona que quieres validar. Usa la plantilla de Globalwork en formato .PDF para validar el documento.\n                "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "load-input",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                      on: { click: _vm.uploadFile },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "16px" },
                                        attrs: {
                                          src: require("./../../assets/images/icons/clip.png"),
                                        },
                                      }),
                                      _vm._v(" \n                  "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    Arrastra y suelta el Habeas Data del candidato aquí o haz clic para buscar en tu ordenador.\n                  "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "slide-fade" } },
                                    [
                                      this.timeout_error
                                        ? _c(
                                            "p",
                                            { staticClass: "timeout-error" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "Algo salió mal. Por favor carga el archivo nuevamente."
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col center-all",
                      staticStyle: { "flex-grow": "1", width: "40%" },
                    },
                    [
                      _c("img", {
                        staticClass: "banners",
                        attrs: {
                          src: require("./../../assets/images/express-search/banner-step1.png"),
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: { enter: 500, leave: 0 } } },
        [
          _vm.step2
            ? _c("div", [
                _c("div", { staticClass: "vx-row step-row" }, [
                  _c("div", { staticClass: "vx-col step-container" }, [
                    _c("h3", { staticClass: "step-title" }, [
                      _vm._v("2. Documento"),
                    ]),
                    !_vm.wrong_data
                      ? _c("div", [
                          _c("p", { staticClass: "step-body" }, [
                            _vm._v(
                              "\n                El Habeas Data Corresponde a la persona\n              "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "vx-row",
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "50%" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "person_name_box" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "vx-row",
                                          staticStyle: {
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "vx-col" }, [
                                            _c(
                                              "div",
                                              { staticClass: "person_name" },
                                              [
                                                _c("p", [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.extracted_data.name
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    "CC " +
                                                      _vm._s(
                                                        this.extracted_data.id
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "vx-col center-all",
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: { width: "24px" },
                                                attrs: {
                                                  src: require("./../../assets/images/icons/person-icon.png"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "50%" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "cofirmation_box" },
                                    [
                                      _c("div", { staticClass: "vx-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "vx-col",
                                            staticStyle: { width: "60%" },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "person_name" },
                                              [
                                                _vm._v(
                                                  "¿Es la persona que quieres validar?"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "vx-col center-all",
                                            staticStyle: { width: "20%" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "confirmation-icon-backgorund center-all",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.updateStep(
                                                      "step3"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "18px",
                                                  },
                                                  attrs: {
                                                    src: require("./../../assets/images/icons/green-check-outline.png"),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "vx-col center-all",
                                            staticStyle: { width: "20%" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "confirmation-icon-backgorund center-all",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.wrongData()
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "18px",
                                                  },
                                                  attrs: {
                                                    src: require("./../../assets/images/icons/wrong-circle.png"),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "wrong-person" }, [
                            _c("img", {
                              staticStyle: { width: "16px" },
                              attrs: {
                                src: require("./../../assets/images/icons/wrong-circle.png"),
                              },
                            }),
                            _vm._v(" \n                "),
                            _c("span", [
                              _vm._v(
                                "Has seleccionado que la persona a la que quieres validar no es la correcta."
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "vx-col center-all",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "reload-btn",
                                    staticStyle: { width: "50%" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reset_step1()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Volver a subir habeas data de nuevo\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col ", staticStyle: { width: "40%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vx-row center-all",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vx-col",
                              staticStyle: {
                                width: "80%",
                                "min-height": "26px",
                              },
                            },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "slide-fade" } },
                                [
                                  this.chekc_two
                                    ? _c(
                                        "div",
                                        { staticClass: "right-person" },
                                        [
                                          _vm._v(
                                            "¡Muy bien, vamos por el último paso!"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-row center-all",
                          staticStyle: { width: "100%", "max-height": "180px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vx-col border-card",
                              staticStyle: { width: "80%" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-row",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col",
                                      staticStyle: {
                                        width: "40%",
                                        padding: "5%",
                                        "%max-height": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "border-card center-all",
                                          staticStyle: {
                                            width: "100%",
                                            padding: "5%",
                                            "max-height": "120px",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: {
                                              src: require("./../../assets/images/express-search/person.png"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col",
                                      staticStyle: {
                                        width: "40%",
                                        "padding-top": "5%",
                                        "max-height": "150px",
                                      },
                                    },
                                    [
                                      _c("p", {
                                        staticClass: "border-card",
                                        staticStyle: {
                                          width: "40%",
                                          height: "13%",
                                          margin: "10% 0",
                                        },
                                      }),
                                      _c("p", {
                                        staticClass: "border-card",
                                        staticStyle: {
                                          width: "80%",
                                          height: "13%",
                                          margin: "10% 0",
                                        },
                                      }),
                                      _c("p", {
                                        staticClass: "border-card",
                                        staticStyle: {
                                          width: "100%",
                                          height: "13%",
                                          margin: "10% 0",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col",
                                      staticStyle: {
                                        width: "15%",
                                        "padding-top": "5%",
                                        "max-height": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "transition",
                                        { attrs: { name: "slide-fade" } },
                                        [
                                          this.chekc_two
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "4.5rem",
                                                },
                                                attrs: {
                                                  src: require("./../../assets/images/icons/green-check.png"),
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: { enter: 900, leave: 500 } } },
        [
          _vm.step3
            ? _c("div", [
                _c("div", { staticClass: "vx-row step-row" }, [
                  _c("div", { staticClass: "vx-col step-container" }, [
                    _c("h3", { staticClass: "step-title" }, [
                      _vm._v("3. Nivel Académico"),
                    ]),
                    _c("p", { staticClass: "step-body" }, [
                      _vm._v(
                        "Selecciona uno para comenzar la búsqueda inmediatamente."
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          staticStyle: { width: "50%" },
                        },
                        [
                          _c("div", { staticClass: "cofirmation_box" }, [
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "5%" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.academic_level,
                                        expression: "academic_level",
                                      },
                                    ],
                                    staticClass: "radio-academic-level",
                                    attrs: {
                                      type: "radio",
                                      id: "radio_basic",
                                      name: "academic_level",
                                      value: "0",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.academic_level, "0"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.academic_level = "0"
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "80%" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "step3-body-card-title" },
                                    [_vm._v("Básico")]
                                  ),
                                  _c("p", { staticClass: "step3-body-card" }, [
                                    _vm._v(
                                      "Buscaremos en algunas instituciones técnicas información del candidato."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "5%" },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "20px" },
                                    attrs: {
                                      src: require("./../../assets/images/icons/profesional-icon.png"),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          staticStyle: { width: "50%" },
                        },
                        [
                          _c("div", { staticClass: "cofirmation_box" }, [
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "5%" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.academic_level,
                                        expression: "academic_level",
                                      },
                                    ],
                                    staticClass: "radio-academic-level",
                                    attrs: {
                                      type: "radio",
                                      id: "radio_profesional",
                                      name: "academic_level",
                                      value: "1",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.academic_level, "1"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.academic_level = "1"
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "80%" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "step3-body-card-title" },
                                    [_vm._v("Universitario")]
                                  ),
                                  !_vm.displayProfesionalLevels
                                    ? _c("div", [
                                        _c(
                                          "p",
                                          { staticClass: "step3-body-card" },
                                          [
                                            _vm._v(
                                              "Buscaremos en algunas instituciones de pregrado, posgrado y/o colegios profesionales."
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selected_profesional_level,
                                                expression:
                                                  "selected_profesional_level",
                                              },
                                            ],
                                            staticClass: "select-levels",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.selected_profesional_level =
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: { disabled: "" },
                                                domProps: { value: null },
                                              },
                                              [
                                                _vm._v(
                                                  "Selecciona una profesión"
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.profesional_levels,
                                              function (level) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: level.id,
                                                    domProps: {
                                                      value: level.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(level.value) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col",
                                  staticStyle: { width: "5%" },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "20px" },
                                    attrs: {
                                      src: require("./../../assets/images/icons/profesional-icon.png"),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col center-all",
                      staticStyle: { "flex-grow": "1", width: "40%" },
                    },
                    [
                      _c("img", {
                        staticClass: "banners",
                        attrs: {
                          src: require("./../../assets/images/express-search/banner-step3.png"),
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: { enter: 500, leave: 500 } } },
        [
          _vm.step4
            ? _c("div", [
                _c("div", { staticClass: "vx-row step-row" }, [
                  _c("div", { staticClass: "vx-col step-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vx-row",
                        staticStyle: { "justify-content": "space-between" },
                      },
                      [
                        _c("div", { staticClass: "vx-col" }, [
                          _c("h3", { staticClass: "step-title" }, [
                            _vm._v("Verificando datos"),
                          ]),
                          _c("div", { staticStyle: { padding: "2% 0" } }, [
                            _c("p", { staticClass: "step4-card-text" }, [
                              _c("strong", [
                                _vm._v(_vm._s(this.extracted_data.name)),
                              ]),
                            ]),
                            _c("p", { staticClass: "step4-card-text" }, [
                              _vm._v("CC " + _vm._s(this.extracted_data.id)),
                            ]),
                            _vm.academic_level === "0"
                              ? _c("p", { staticClass: "step4-card-text" }, [
                                  _vm._v("Nivel Académico Básico"),
                                ])
                              : _vm._e(),
                            _vm.academic_level === "1"
                              ? _c("p", { staticClass: "step4-card-text" }, [
                                  _vm._v("Nivel Académico Profesional"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col center-all",
                            staticStyle: { width: "20%" },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "30px" },
                              attrs: {
                                src: require("./../../assets/images/icons/person-icon.png"),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col center-all",
                      staticStyle: { "flex-grow": "1", width: "40%" },
                    },
                    [
                      _c("img", {
                        staticClass: "banners",
                        attrs: {
                          src: require("./../../assets/images/express-search/banner-step4.png"),
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: { enter: 500, leave: 500 } } },
        [
          _vm.step4
            ? _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col loader-bar" }, [
                  _c("span", { staticClass: "loader-bar-text" }, [
                    _c("span", [_vm._v(_vm._s(_vm.progress) + "%, ")]),
                    _vm._v(
                      " Cargando resultados, esto puede tardar unos segundos..."
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "vs-progress",
                        {
                          attrs: {
                            percent: _vm.progress,
                            indeterminate: _vm.progress < 1,
                            color: "primary",
                          },
                        },
                        [_vm._v("primary")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", [
        _c("div", [
          _c("input", {
            ref: "file",
            staticStyle: { display: "none" },
            attrs: {
              id: "habeasFile",
              type: "file",
              accept: "image/jpeg,.pdf",
            },
            on: { change: _vm.onFileChange },
          }),
        ]),
      ]),
      _vm.step4
        ? _c("div", [
            _c(
              "div",
              [
                _c("general-section", {
                  attrs: {
                    name: _vm.extracted_data.name,
                    express_search_id: _vm.express_search_id,
                    identification_number: _vm.extracted_data.id,
                    academic_level: _vm.academic_level,
                    profesional_level: _vm.selected_profesional_level,
                  },
                  on: { progress: _vm.setProgress, reset: _vm.reload },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }